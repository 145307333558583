import { colors, breakpoints } from '../utils/preset';

const globalStyle = {
    body: {
        fontFamily: '"Cera PRO", sans-serif',
        background: colors.background,
        color: colors.theme,
        fontSize: 14,
        [breakpoints.lg]: {
            overflow: 'hidden',
        },
        [breakpoints.xl]: {
            fontSize: 16,
        },
    },
    a: {
        color: 'inherit',
    },
    img: {
        maxWidth: '100%',
    },
    'div[role=button]': {
        cursor: 'pointer',
    },
    '::-webkit-scrollbar': {
        width: 2,
    },
    '::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
        background: colors.theme,
    },
};

export default globalStyle;
