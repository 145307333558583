import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import ogImage from '../images/agnes-og-image.png';

const SEO = ({
    title,
    description = '',
    lang = 'en',
    meta = [],
}) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    siteUrl
                }
            }
        }
    `);

    const metaTitle = ((title === 'Home' || title === '404')
        ? site.siteMetadata.title
        : `${ title } | Agnes`);
    const metaDescription = description || site.siteMetadata.description;
    const siteUrl = site.siteMetadata.siteUrl;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `keywords`,
                    content: `Agnes, Agnes Restaurant, wood-fired Restaurant, wood fire, fire-powered restaurant, 22 Agnes Street, Agnes Street, Fortitude Valley, Brisbane, Queensland`,
                },
                {
                    name: `image`,
                    content: ogImage,
                },
                {
                    property: `og:url`,
                    content: siteUrl,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: ogImage,
                },
                {
                    property: `og:image:width`,
                    content: '1200',
                },
                {
                    property: `og:image:height`,
                    content: '630',
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    property: `twitter:image`,
                    content: ogImage,
                },
            ].concat(meta)}
        />
    );
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

export default SEO;
