/** @jsx jsx */
import { jsx } from '@emotion/core';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import { colors, breakpoints } from '../../utils/preset';

const BodyBgWrapper = ({
    children,
    className,
}) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    bodyBg: file(relativePath: { eq: "paper-texture_Large.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 1920, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `}
            render={data => (
                <BackgroundImage
                    fluid={data.bodyBg.childImageSharp.fluid}
                    backgroundColor={colors.background}
                    className={className}
                    css={{
                        width: '100%',
                        minHeight: '100vh',
                        [breakpoints.lg]: {
                            height: '100vh',
                        },
                    }}
                >
                    {children}
                </BackgroundImage>
            )}
        />
    );
};

export default BodyBgWrapper;
